<template>
<opm-page :ext-info="extInfo" :wx-share="wxShare">
  <ion-content fullscreen class="ion-padding">
    <div style="font-size: 1.4em;font-weight: 500">{{ news.title }}</div>
    <div style="font-size: 0.8em;color: #34394D;margin-top: 7px">
      <div style="vertical-align: middle;display: inline-block"><img style="" src="https://static.yuzhangmen.cn/logo.jpg?x-oss-process=image/resize,w_100" width="16" /></div>
      <div style="display: inline-block;margin-left:2px">鱼掌门官方</div>
    </div>
    <div style="font-size: 0.8em; color: #BBBDC4;margin-top: 7px">
      行情数据 · {{ $filters.dayjs(news.createdTime, 'YYYY.MM.DD') }} · {{ news.readCount }}次阅读
    </div>
    <div style="font-size: 16px;color: #34394D;line-height: 24px" v-html="news.content" id="content">
    </div>
  </ion-content>
</opm-page>
</template>

<script>
import { IonContent } from '@ionic/vue';
import { defineComponent } from 'vue';
import {useRoute} from "vue-router";
import OpmPage from "@/components/OpmPage";

export default defineComponent({
  name: "news",
  data() {
    return {
      route: useRoute(),
      news: {},
      extInfo: `yzm:/${location.pathname}`,
      wxShare: {}
    }
  },
  mounted() {
    const newsId = this.route.params.id
    console.log(this.extInfo)
    this.$axios.get(`news/${newsId}`).then(resp => {
      this.news = resp.result
      document.title = this.news.title
      this.wxShare = { title: this.news.title, desc: document.getElementById('content').innerText || document.title, link: location.href, imgUrl: 'https://static.yuzhangmen.cn/logo.jpg' }
    })
  },
  methods: {

  },
  components: {
    IonContent,
    OpmPage
  },
})
</script>

<style scoped>

</style>